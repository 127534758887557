import { Icon } from '@palmetto/palmetto-components';
import { ApiFinanceAccountOverview } from 'api/payments/types';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import { useTranslation } from 'components/_app/TranslationProvider/TranslationProvider';
import styles from './FinanceAccountsList.module.scss';

export interface Props {
  financeAccounts: ApiFinanceAccountOverview[];
}

const FinanceAccountsList = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const { financeAccounts } = props;

  return (
    <Card className={styles.financeAccountList} semiTransparentBg>
      <h2 className={styles.superscript}>{t('financeAccountsList.accounts')}</h2>
      <ul className={styles.list}>
        {financeAccounts.map((financeAccount) => {
          const scheduledDate = new Date(financeAccount.nextScheduledAutoPaymentDate);
          const today = new Date();
          const dueInDays =
            scheduledDate && Math.floor((today.getTime() - scheduledDate.getTime()) / (1000 * 60 * 60 * 24));
          const title = t(`financeAccounts.planName.${financeAccount.product}`, {
            defaultValue: financeAccount.product,
          });
          return (
            <li className={styles.listItem} key={financeAccount.id}>
              <Button
                isNaked
                className={styles.clickableRow}
                to={`/finance-accounts/${financeAccount.id}`}
                trackingId="finance-account-list-item"
              >
                <div className={styles.planName} title={title}>
                  {title}
                </div>
                <div className={styles.details}>
                  <div className={styles.detail}>
                    <label className={styles.label}>{t('financeAccountsList.account')}</label>
                    <div className={styles.detailValue}>
                      {String(t('financeAccountsList.accountValue', { last4: financeAccount.accountId.slice(-4) }))}
                    </div>
                  </div>
                  <div className={styles.detail}>
                    <label className={styles.label}>{t('financeAccountsList.amount')}</label>
                    <div className={styles.detailValue}>
                      {String(t('financeAccountsList.amountValue', { value: financeAccount.totalDue }))}
                    </div>
                  </div>
                  <div className={styles.detail}>
                    <div>
                      <label className={styles.label}>{t('financeAccountsList.dueIn')}</label>
                      <div className={styles.detailValue}>{String(t('common.days_other', { days: dueInDays }))}</div>
                    </div>
                    <Icon name="caret-right" className={styles.icon} />
                  </div>
                </div>
              </Button>
            </li>
          );
        })}
      </ul>
    </Card>
  );
};
export default FinanceAccountsList;
