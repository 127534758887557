import * as FullStory from '@fullstory/browser';
import { AxiosError } from 'axios';
import { LDClient } from 'launchdarkly-js-client-sdk';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import { datadogRum } from '@datadog/browser-rum';
import { toast } from '@palmetto/palmetto-components';
import {
  checkUserExists,
  LoginUser,
  LoginUserResponse,
  postResetPassword,
  signup,
  SignupUser,
} from 'api/login/login';
import { User, UserSettings } from 'api/user/types';
import { getUser, putUser } from 'api/user/user';
import authContext from 'components/_app/AuthProvider/authContext';
import { useTranslation } from 'components/_app/TranslationProvider/TranslationProvider';
import { getSegmentContextDefaultProperties } from 'components/_tracking/Segment/Segment';
import useDebug from 'hooks/useDebug';
import { ROUTE } from 'routes';
import {
  clearCredentialStorage,
  isImpersonatedUser,
  removeImpersonationCookie,
  stopImpersonating,
} from 'utils/auth';
import { getFriendlyError, getFriendlySignUpError } from 'utils/error';

export interface UseUser {
  login: UseMutateFunction<LoginUserResponse, AxiosError, LoginUser>;
  logout: () => void;
  isAuthenticated: boolean;
  user: User | undefined;
  updateUser: UseMutateFunction<User, AxiosError, Partial<User>>;
  updateUserSettings: UseMutateFunction<UserSettings, AxiosError, Partial<UserSettings>>;
  resetPassword: UseMutateFunction<void, AxiosError, string>;
  signupUser: UseMutateFunction<void, AxiosError, SignupUser>;
  checkUserExists: (token: string) => Promise<boolean>;
  isImpersonatedUser: () => boolean;
  stopImpersonating: () => void;
  isLoading: boolean;
  isFetched: boolean;
  isIdentifying: boolean;
  error?: string;
  invalidateQueries: () => void;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
}

export const QUERY_KEY = 'user';

const pagesThatShouldNotRefetchOnWindowFocus = [
  ROUTE.PAYMENT_METHOD,
  ROUTE.ONBOARDING_SITE_SURVEY,
  ROUTE.ONBOARDING_ENERGY_BILL,
  ROUTE.ONBOARDING_INCOME_VERIFICATION,
  ROUTE.ONBOARDING_TITLE_VERIFICATION,
  ROUTE.HOME,
  ROUTE.PAYMENT,
  ROUTE.QUESTIONNAIRE,
];

const shouldRefetchOnWindowFocus = (pathname: string) => {
  return !pagesThatShouldNotRefetchOnWindowFocus.some((page) => pathname.startsWith(page));
};

const identifyUser = async (
  user: User,
  launchDarkly: LDClient | undefined,
  onStartLD: () => void,
  onCompleteLD: () => void,
) => {
  // Segment
  try {
    window.analytics?.identify(
      user.auth0Id,
      {
        userFullName: `${user.firstName} ${user.lastName}`,
        email: user.email,
      },
      {
        ...(user && { anonymousId: user.email }),
        context: getSegmentContextDefaultProperties(),
      },
    );
  } catch (e) {
    datadogRum.addError('Error identifying user for Segment:', e.message);
  }

  // LaunchDarkly
  try {
    if (launchDarkly) {
      onStartLD();
      launchDarkly
        .identify({
          key: user.auth0Id,
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
          firstName: user.firstName,
          lastName: user.lastName,
          anonymous: false,
        })
        .finally(() => onCompleteLD());
    } else {
      datadogRum.addError('Failed to identify user for LaunchDarkly: launchDarkly is undefined');
    }
  } catch (e) {
    datadogRum.addError('Error identifying user for LaunchDarkly:', e.message);
    onCompleteLD();
  }

  // FullStory
  try {
    FullStory.identify(user.email, {
      email: user.email,
      displayName: `${user.firstName} ${user.lastName}`,
      isImpersonatedUser: isImpersonatedUser(),
    });
  } catch (e) {
    datadogRum.addError('Error identifying user for FullStory:', e.message);
  }
};

export const useUser = (params?: { refetchInterval?: number }): UseUser => {
  const launchDarkly = useLDClient();
  const { refetchInterval } = params || {};
  const { login, logout, isAuthenticated, isAuthLoading, loginError, setIsAuthenticated } = useContext(authContext);
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const debug = useDebug();
  const [isIdentifying, setIsIdentifying] = useState(false);
  const { t } = useTranslation();

  const {
    data: user,
    error: userError,
    isLoading: isUserLoading,
    isFetched,
  } = useQuery<User | undefined, AxiosError>(
    [QUERY_KEY, isAuthenticated],
    async () => {
      try {
        const u = await getUser();
        debug('useUser getUser', { user: u });
        if (u) {
          if (u.auth0Id && !isAuthLoading) {
            await identifyUser(
              u,
              launchDarkly,
              () => setIsIdentifying(true),
              () => setIsIdentifying(false),
            );
          }
        }
        return u;
      } catch (e) {
        if (e.response?.status === 401) {
          toast.error(t('common.toasts.unauthorizedUser'));
          logoutUser();
        }
        if (isImpersonatedUser() && e.response?.status === 403) {
          toast.error(t('common.toasts.impersonationError'));
          removeImpersonationCookie();
          logoutUser();
        }
        throw e;
      }
    },
    {
      enabled: isAuthenticated,
      retry: false,
      refetchOnWindowFocus: shouldRefetchOnWindowFocus(pathname),
      refetchInterval,
    },
  );

  /*
   If starting polling, then clear the query cache
   If ending polling, do not clear the query cache
   */
  const previousRefetchInterval = useRef<number>();
  useEffect(() => {
    if (previousRefetchInterval.current !== refetchInterval) {
      previousRefetchInterval.current = refetchInterval;
      if (refetchInterval) {
        queryClient.invalidateQueries(QUERY_KEY);
      }
    }
  }, [refetchInterval, previousRefetchInterval, queryClient]);

  const {
    mutate: updateUser,
    error: updateUserError,
    isLoading: isUpdateUserLoading,
  } = useMutation<User, AxiosError, Partial<User>>(putUser, {
    onMutate: (u) => Boolean(u.email) && clearCredentialStorage(),
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEY),
  });

  const updateUserSettings = useCallback(
    (settings: UserSettings, options) => updateUser({ settings }, options),
    [updateUser],
  );

  const {
    mutate: signupUser,
    error: signupUserError,
    isLoading: isSignupUserLoading,
  } = useMutation<void, AxiosError, SignupUser>(signup);

  const {
    mutate: resetPassword,
    isLoading: isResetPasswordLoading,
    error: resetPasswordError,
  } = useMutation<void, AxiosError, string>(postResetPassword, {
    onMutate: clearCredentialStorage,
  });

  const logoutUser = () => {
    logout();
  };

  return {
    updateUser,
    updateUserSettings,
    isAuthenticated,
    user,
    login,
    logout: logoutUser,
    resetPassword,
    signupUser,
    checkUserExists,
    isImpersonatedUser,
    stopImpersonating,
    isLoading:
      isUpdateUserLoading || isUserLoading || isResetPasswordLoading || isSignupUserLoading || isAuthLoading,
    isIdentifying,
    isFetched,
    invalidateQueries: () => queryClient.invalidateQueries(QUERY_KEY),
    error:
      loginError ||
      getFriendlySignUpError(signupUserError) ||
      getFriendlyError(updateUserError || userError || resetPasswordError, 'user'),
    setIsAuthenticated,
  };
};
