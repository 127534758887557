export enum ROUTE {
  AUTH0 = '/auth0',
  CHECK_UPLOAD_PAYMENT_METHOD = '/payment/manual-bank-connection',
  DASHBOARD = '/dashboard',
  DASHBOARD_ABOUT = '/dashboard/about',
  DEEPLINK = '/deeplink',
  ENERGY_ADVISOR = '/energy-advisor',
  ENERGY_PROFILE = '/energy-profile',
  FINANCE_ACCOUNTS = '/finance-accounts',
  FINANCE_ACCOUNTS_DETAIL = '/finance-accounts/:id',
  HOME = '/',
  INSTANT_ACH_PAYMENT_METHOD = '/payment/instant-bank-connection',
  LEGACY_ACCOUNT = '/account',
  LEGACY_ONBOARDING = '/onboarding',
  LOGOUT = '/logout',
  MOCK = '/mock',
  ONBOARDING = '/onboarding',
  ONBOARDING_ENERGY_BILL = '/onboarding/energy-bill',
  ONBOARDING_HOMEOWNERS_ASSOCIATION = '/onboarding/homeowners-association',
  ONBOARDING_INCOME_VERIFICATION = '/onboarding/income-verification',
  ONBOARDING_SITE_SURVEY = '/onboarding/site-survey',
  ONBOARDING_TITLE_VERIFICATION = '/onboarding/title-verification',
  ONBOARDING_TREE_REMOVAL = '/onboarding/tree-removal',
  PANEL_PLACEMENT = '/panel-placement',
  PASSWORD_RESET = '/password-reset',
  PAYMENT = '/payment',
  PAYMENT_CHECK_UPLOAD = '/payment/check-upload',
  PAYMENT_METHOD = '/payment/payment-method',
  PAYMENT_SETUP = '/payment/setup',
  PHYSICAL_SITE_SURVEY_AVAILABILITY = '/physical-site-survey-availability',
  PROFILE = '/profile',
  PROPERTIES_BUILDING_ATTRIBUTE_UPDATE = '/properties/:propertyId/building/:buildingAttribute',
  QUESTIONNAIRE = '/questionnaire/:id',
  REFER = '/refer',
  SERVICE = '/service',
  SIGNUP = '/signup',
  SUPPORT = '/support',
  UTILITY_PROVIDER = '/utility-provider',
}
