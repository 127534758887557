import request from '../index';

import { ApiSiteStage } from './types';

export const STAGE_ENDPOINT = '/sites/stage';

export async function getSiteStage(): Promise<ApiSiteStage> {
  const result = await request({ url: STAGE_ENDPOINT });
  return result.data;
}
