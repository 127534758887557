import { AxiosError } from 'axios';
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from 'react-query';

import request from 'api/index';
import { BillingCycle } from 'api/payments/types';
import { useUser } from 'api/user/useUser';
import { PlatformConfig } from 'config';
import { getFriendlyError } from 'utils/error';

const ENDPOINT = '/subscriptions';

export interface Subscription {
  id: string;
  productId: string;
  priceId: string;
  price: number | null;
  tier: string;
  tierName: string;
  category: string;
  billingInterval?: BillingCycle;
  startDate?: string;
  endDate?: string;
}

export interface UseSubscriptions {
  data: {
    subscription?: Subscription;
    scheduledSubscription?: Subscription;
  };
  error?: string;
  scheduledError?: string;
  isLoading: boolean;
  subscribing: boolean;
  isFetched: boolean;
  subscribe: UseMutateFunction<void, AxiosError, postSubscriptionParams>;
}

export interface postSubscriptionParams {
  priceId: string;
  couponCode?: string;
}

async function getSubscriptions() {
  const result = await request({ url: `${ENDPOINT}` });
  return result.data;
}

async function getScheduledSubscriptions() {
  const result = await request({ url: `${ENDPOINT}/scheduled` });
  return result.data;
}

async function postSubscription(postSubscriptionParams: postSubscriptionParams): Promise<void> {
  const { priceId, couponCode } = postSubscriptionParams;
  const result = await request({
    method: 'post',
    url: '/subscriptions',
    data: {
      priceId,
      platform: PlatformConfig.platform,
      couponCode,
    },
  });
  return result.data;
}

// Display custom error message if user tries to checkout without creating a payment method
const getFriendlySubscribeError = (error: AxiosError | null): string | undefined => {
  if (error) {
    return error?.response?.data?.errors?.includes('User must have a card created')
      ? 'A valid credit card is required to process this request.'
      : 'Error purchasing subscription';
  }
  return undefined;
};

// Hide 404 error message if user doesn't have a subscription
const getFriendlyErrors = (error: AxiosError | null): string | undefined => {
  if (error && !error.response?.data?.errors?.includes('User has no subscription')) {
    return getFriendlyError(error, 'subscription options');
  }
  return undefined;
};

export function useSubscriptions(): UseSubscriptions {
  const { isAuthenticated } = useUser();
  const queryClient = useQueryClient();

  const {
    data: subscription,
    error,
    isLoading,
    isFetched,
  } = useQuery<Subscription, AxiosError>('subscriptions', getSubscriptions, {
    enabled: isAuthenticated,
  });

  const {
    data: scheduledSubscription,
    error: scheduledError,
    isLoading: scheduledIsLoading,
    isFetched: scheduledIsFetched,
  } = useQuery<Subscription, AxiosError>('scheduled-subscriptions', getScheduledSubscriptions, {
    enabled: isAuthenticated && Boolean(subscription),
  });

  // Use mutate instead of mutateAsync
  // "Since mutateAsync gives you control over the Promise, you also have to catch errors manually, or you might get an unhandled promise rejection."
  // See https://tkdodo.eu/blog/mastering-mutations-in-react-query#mutate-or-mutateasync
  const {
    mutate: subscribe,
    error: subscribeError,
    isLoading: subscribing,
  } = useMutation<void, AxiosError, postSubscriptionParams>(['subscriptions'], postSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries('subscriptions');
      queryClient.invalidateQueries('scheduled-subscriptions');
    },
  });

  return {
    data: { subscription, scheduledSubscription },
    error: getFriendlyErrors(error) || getFriendlySubscribeError(subscribeError),
    scheduledError: getFriendlyErrors(scheduledError),
    isLoading: isLoading || scheduledIsLoading,
    subscribing,
    isFetched: isFetched || scheduledIsFetched,
    subscribe,
  };
}
