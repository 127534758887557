import request from 'api/index';
import { PlatformConfig, StripeConfig } from 'config';

import { ListResponse } from 'core/listResponse';
import {
  ApiFinanceAccount,
  ApiFinanceAccountOverview,
  ApiPaymentMethod,
  PaymentMethodType,
  StripeVerificationMethod,
} from './types';

export const PAYMENTS_ENDPOINT = '/payments';
export const FINANCE_ACCOUNT_ENDPOINT = '/finance-accounts';

export async function getPaymentMethod(): Promise<ApiPaymentMethod> {
  const result = await request({ url: `${PAYMENTS_ENDPOINT}/paymentMethod` });
  return result.data;
}

export async function setup(
  types: PaymentMethodType[],
  verificationMethod: StripeVerificationMethod,
): Promise<string> {
  // Get dummy client_secret value directly from Stripe for tests
  if (PlatformConfig.environment === 'test') {
    const result = await fetch('https://api.stripe.com/v1/payment_intents', {
      body: `amount=2000&currency=usd${types.map((type) => `&payment_method_types[]=${type}`).join('')}`,
      headers: {
        Authorization: `Bearer ${StripeConfig.apiSecretKey}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
    }).then((res) => res.json());
    return result.client_secret;
  }

  const result = await request({
    method: 'post',
    url: `${PAYMENTS_ENDPOINT}/setup/payment`,
    data: { type: types[0], isNative: PlatformConfig.isNative, verificationMethod },
  });
  return result.data.client_secret;
}

export async function setDefaultPaymentMethod(setupIntentId: string): Promise<void> {
  await request({ method: 'put', url: `${PAYMENTS_ENDPOINT}/paymentMethods/default`, data: { setupIntentId } });
}

export async function getFinanceAccount(financeAccountId: string): Promise<ApiFinanceAccount> {
  const result = await request({ url: `${FINANCE_ACCOUNT_ENDPOINT}/${financeAccountId}` });
  return result.data;
}

export async function getFinanceAccounts(): Promise<ListResponse<ApiFinanceAccountOverview>> {
  const result = await request({ url: `${FINANCE_ACCOUNT_ENDPOINT}` });
  return result.data;
}
