import { App as CapApp } from '@capacitor/app';
import { useUser } from 'api/user/useUser';
import { trackEvent } from 'components/_tracking/Segment/Segment';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useNativeAppListener = (): void => {
  const history = useHistory();
  const { user, isAuthenticated } = useUser();
  useEffect(() => {
    CapApp.addListener('appUrlOpen', (data) => {
      const { pathname, search } = new URL(data.url);
      if (pathname) {
        history.push(`${pathname}${search}`);
      }
    });
  });

  useEffect(() => {
    CapApp.addListener('resume', () => {
      if (isAuthenticated && user?._id) {
        trackEvent('Application Opened');
      }
    });
  }, [isAuthenticated, user?._id]);
};

export default useNativeAppListener;
