// TODO: allow canceling requests (for when a user clicks the next/back arrows repeatedly

import { datadogRum } from '@datadog/browser-rum';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { PlatformConfig } from 'config';
import { getImpersonatedUserId, getToken } from 'utils/auth';
import { getSharedContentToken } from 'utils/sharedContent';

const BASE_URL = `${PlatformConfig.baseApiUrl}/api/v1`;

const TIMEOUT = 30000; // timeout the API request after 30 seconds

const createAxiosRequest = () => {
  const axiosRequest = axios.create();
  axiosRequest.interceptors.response.use(
    (r) => r,
    async (error) => {
      if (error.response?.status === 400) {
        datadogRum.addError(error);
      }
      // eslint-disable-next-line no-param-reassign
      error.message = `${error.config?.method?.toUpperCase()} ${error.config?.url} - ${error.message}`;
      return Promise.reject(error);
    },
  );
  return axiosRequest;
};

export function noAuthRequest({ data, method = 'get', params, url }: AxiosRequestConfig): Promise<AxiosResponse> {
  const reqUrl = `${BASE_URL}${url}`;
  return createAxiosRequest()({
    data,
    method,
    params,
    url: reqUrl,
  });
}

export default async function request(config: AxiosRequestConfig): Promise<AxiosResponse> {
  const { headers, method = 'get', url } = config;
  const impersonatedUserId = getImpersonatedUserId();
  const reqUrl = impersonatedUserId ? `${BASE_URL}/impersonation/${impersonatedUserId}${url}` : `${BASE_URL}${url}`;
  const token = getToken() || getSharedContentToken();
  return createAxiosRequest()({
    ...config,
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    method,
    timeout: TIMEOUT,
    url: reqUrl,
  });
}
