import React from 'react';

import Page from 'components/Page/Page';

import { useFinanceAccountsList } from 'api/payments/useFinanceAccountsList';
import { useUser } from 'api/user/useUser';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import { Redirect } from 'react-router';
import { ROUTE } from 'routes';
import FinanceAccountsList from '../components/FinanceAccountsList/FinanceAccountsList';
import PaymentLinks from '../components/PaymentLinks/PaymentLinks';
import styles from './FinanceAccountsList.module.scss';

const FinanceAccounts: React.FC = () => {
  const { user } = useUser();
  const loadFinanceAccounts = Boolean(user?.financeAccounts && user.financeAccounts.length > 1);
  const { data: financeAccounts, error, isLoading } = useFinanceAccountsList(loadFinanceAccounts);

  if (user && (!user.financeAccounts || user.financeAccounts.length === 0)) {
    return <Redirect to={ROUTE.PAYMENT} />;
  }
  if (user && user.financeAccounts && user.financeAccounts.length === 1) {
    return <Redirect to={ROUTE.FINANCE_ACCOUNTS_DETAIL.replace(':id', user.financeAccounts[0]._id)} />;
  }

  if (isLoading) {
    return <LoadingPage />;
  }
  if (error || !financeAccounts) {
    return (
      <Page layout="singleItem">
        <ErrorMessage error={error} />
      </Page>
    );
  }

  return (
    <Page layout="grid" className={styles.page}>
      <section>
        <FinanceAccountsList financeAccounts={financeAccounts.data} />
      </section>
      <section>
        <PaymentLinks />
      </section>
    </Page>
  );
};

export default FinanceAccounts;
