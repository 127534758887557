/* eslint-disable react/no-unused-prop-types */
import { Modal as PalmettoModal } from '@palmetto/palmetto-components';
import { BoxProps } from '@palmetto/palmetto-components/dist/components/Box/Box';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import React, { useContext } from 'react';

import { ReactComponent as TimesIcon } from 'assets/font-awesome-5/times.svg';
import { useThemeContext } from 'components/_app/ThemeProvider/ThemeProvider';
import { trackEvent } from 'components/_tracking/Segment/Segment';
import { PlatformConfig } from 'config';

import styles from './Modal.module.scss';

export interface Props {
  id: string;
  className?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  isOpen: boolean;
  onDismiss?: () => void;
  fullScreenMobile?: boolean;
  fullscreen?: boolean;
  fullWidthButtons?: boolean;
  maxWidth?: BoxProps['maxWidth'];
  trackingId?: string;
}

const Modal: React.FC<Props> = (props: Props) => {
  const {
    id,
    className,
    header,
    footer,
    children,
    fullScreenMobile,
    fullscreen,
    isOpen,
    onDismiss,
    fullWidthButtons,
    maxWidth,
  } = props;

  const { themeContainerRef } = useThemeContext(); // render within each theme instead of in body

  const trackingId = props.trackingId || id;

  return (
    <>
      {(() => {
        // For proper display in multi-themed Chromatic stories
        // Ideally we could pass an overlayClassName to PalmettoModal
        if (PlatformConfig.isChromatic && fullscreen) {
          return (
            <style>{`
              @media (min-width: 680px) {
                div[class^=Modal-module_modal] div[class^=Modal-module_container] {
                  display: block;
                }
              }
            `}</style>
          );
        }
        return null;
      })()}
      <PalmettoModal
        containerRef={themeContainerRef}
        className={classNames(className, {
          [styles.fullWidthButtons]: fullWidthButtons,
          [styles.fullscreen]: fullscreen,
        })}
        isOpen={isOpen}
        fullScreenMobile={fullScreenMobile || fullscreen}
        maxWidth={maxWidth}
        onDismiss={onDismiss || (() => undefined)}
        data-tracking-id={trackingId}
      >
        {header && (
          <div className={styles.header}>
            {header}
            {onDismiss && (
              <button type="button" onClick={onDismiss} aria-label="close modal">
                <TimesIcon className={styles.close} />
              </button>
            )}
          </div>
        )}
        {children && <div className={styles.body}>{children}</div>}
        {footer && <div className={styles.footer}>{footer}</div>}
      </PalmettoModal>
    </>
  );
};

const ModalContext = React.createContext<{
  modal: Props | undefined;
  setModal: (props: Props | undefined) => void;
}>({
  modal: undefined,
  setModal: () => {
    /* no-op */
  },
});

const GlobalModal: React.FC = () => {
  const { modal } = useContext(ModalContext);
  if (modal) {
    return <Modal {...modal} />;
  }
  return null;
};

export const ModalProvider: React.FC = ({ children }: { children?: React.ReactNode }) => {
  const [modal, setModal] = React.useState<Props>();
  const settingModal = React.useRef<Props>();

  if (isEqual(modal, settingModal.current)) {
    settingModal.current = undefined;
  }

  const value = React.useMemo(
    () => ({
      modal,
      setModal: (m: Props | undefined) => {
        if (!settingModal.current) {
          settingModal.current = m;
          setModal(m);
        }
      },
    }),
    [modal],
  );

  return (
    <ModalContext.Provider value={value}>
      {children}
      <GlobalModal />
    </ModalContext.Provider>
  );
};

const LocalModal: React.FC<Props> = (props: Props) => {
  const { id, isOpen } = props;
  const trackingId = props.trackingId || id;

  const { modal, setModal } = useContext(ModalContext);

  const trackedModalOpen = React.useRef(false);

  const isGlobalModal = modal && modal.id === id;
  const isOpenGlobally = isOpen && isGlobalModal;

  const willOpenGlobally = isOpen && !modal;

  React.useEffect(() => {
    if (isOpenGlobally === undefined) return;
    let isClosingButHasNeverBeenOpened = !isOpenGlobally && !trackedModalOpen.current;

    if (isClosingButHasNeverBeenOpened) return;
    trackedModalOpen.current = true;
    trackEvent(`modal ${isOpenGlobally ? 'open' : 'close'}`, {
      trackingId,
      page: window.location.href,
    });
  }, [trackingId, isOpenGlobally]);

  // set/update modal
  React.useEffect(() => {
    if (isGlobalModal) {
      // update modal
      setModal(isOpen ? props : undefined);
    } else if (willOpenGlobally) {
      // set modal
      setModal(props);
    }
  }, [isOpen, props, setModal, isGlobalModal, willOpenGlobally]);

  // remove modal on unmount
  React.useEffect(() => {
    return () => {
      setModal(undefined);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default React.memo(LocalModal);
