import i18next, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

const NAMESPACE = 'palmettoApp';

export interface Resource {
  [language: string]: {
    [namespace: string]: {
      [key: string]: unknown;
    };
  };
}

const getResources = (): Resource => {
  const dependencies = import.meta.glob<true, string, Record<string, unknown>>('./locales/**/*.json', {
    eager: true,
  });
  const resource = Object.keys(dependencies).reduce((accumulator: Resource, key: string) => {
    if (key.indexOf('locales') === 2) {
      const locale = key.split('/')[2];
      const value = dependencies[key];
      if (!accumulator[locale]) {
        accumulator[locale] = {};
      }
      accumulator[locale][NAMESPACE] = { ...accumulator[locale][NAMESPACE], ...value };
    }
    return accumulator;
  }, {});

  return resource;
};
export const resources = getResources();

export const options: InitOptions = {
  // if you're using a language detector, do not define the `lng` option
  // https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  // lng: window.navigator.language,
  fallbackLng: 'en-US',
  supportedLngs: ['en-US', 'es-US'],
  ns: [NAMESPACE],
  resources,
  detection: {
    // https://github.com/i18next/i18next-browser-languageDetector#detector-options
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lang',
  },
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['p', 'ul', 'li', 'a', 'br', 'b', 'span', 'strong', 'em', 'i'],
  },
};

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(options);

i18next?.services?.formatter?.add('dateFormatter', (value, lng, params) => {
  let format: Intl.DateTimeFormatOptions;
  switch (params.dateFormat) {
    case 'weekdayMonth':
      format = { weekday: 'long', month: 'long', day: 'numeric' };
      break;
    default:
      format = {};
      break;
  }
  return new Intl.DateTimeFormat(lng, format).format(new Date(value));
});

export default i18next;
