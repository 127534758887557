import { BaseButtonProps, ButtonVariant, Button as PalmettoButton } from '@palmetto/palmetto-components';
import classNames from 'classnames';
import { trackClick } from 'components/_tracking/Segment/Segment';
import { useNavigation } from 'hooks/useNavigation';
import React from 'react';
import styles from './Button.module.scss';

interface BaseProps extends Omit<BaseButtonProps, 'variant'> {
  trackingId: string;
  trackingProperties?: Record<string, unknown>;
  to?: string;
  onClick?: (e: { preventDefault: () => void; stopPropagation: () => void }) => void;
  variant?: ButtonVariant | 'text'; // for backwards compatibility
  disabled?: boolean; // for backwards compatibility
  type?: 'submit' | 'reset' | 'button';
  form?: string;
  disablePill?: boolean;
  isNaked?: boolean;
}

interface NormalButtonProps extends BaseProps {
  as?: 'button';
}

interface AnchorButtonProps extends BaseProps {
  as: 'a';
}

export type Props = NormalButtonProps | AnchorButtonProps;

const Button: React.FC<Props> = (props: Props) => {
  const { openURL } = useNavigation();

  const palmettoProps = {
    ...props,
    className: classNames(
      styles.button,
      props.className,
      { [styles.text]: props.variant === 'text' },
      { [styles.isPill]: props.disablePill !== true },
    ),
    as: props.to ? 'a' : props.as,
    href: props.to,
    onClick: (e: { preventDefault: () => void; stopPropagation: () => void }) => {
      trackClick(props.trackingId, 'button', {
        href: props.to,
        type: props.type,
        page: window.location.href,
        ...props.trackingProperties,
      });
      if (props.onClick) {
        props.onClick(e);
      }
      if (to) {
        e.preventDefault();
        openURL(to);
      }
    },
    iconSuffix: props.iconSuffix,
    variant: props.variant === 'text' ? undefined : props.variant, // for backwards compatibility
    isNaked: props.isNaked || props.variant === 'text', // for backwards compatibility
    isDisabled: props.isDisabled || props.disabled, // for backwards compatibility
  };

  const { as, to, href, children, trackingId, trackingProperties, isNaked, ...restProps } = palmettoProps;

  if (props.isNaked) {
    return (
      // eslint-disable-next-line react/button-has-type
      <button type={palmettoProps?.type || 'button'} {...restProps} data-tracking-id={trackingId}>
        {children}
      </button>
    );
  }

  if (as === 'a') {
    return (
      <PalmettoButton {...restProps} as={as} href={href} data-tracking-id={trackingId}>
        {children}
      </PalmettoButton>
    );
  }

  return (
    <PalmettoButton {...restProps} data-tracking-id={trackingId}>
      {children}
    </PalmettoButton>
  );
};

export default Button;
