export enum AssociatedEntityTypes {
  epc = 'epc',
  alchemySalesRep = 'alchemySalesRep',
  alchemyCustomerLead = 'alchemyCustomerLead',
  sabalOrganization = 'sabalOrganization',
  luminanceEstimate = 'luminanceEstimate',
}

export interface AssociatedEntity {
  entityType: AssociatedEntityTypes;
  id: string;
}
