import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, useLocation } from 'react-router';

import LoadingPage from 'components/LoadingPage/LoadingPage';
import { ONBOARDING_FEED_ITEM_ROUTES } from 'components/Routes/OnboardingFeedItems';
import Route from 'components/Routes/Route';
import { FeatureConfig } from 'config';
import PageNotFound from 'pages/PageNotFound/PageNotFound';
import { ROUTE } from 'routes';

import FinanceAccountsList from 'pages/PaymentsV3/FinananceAccountsList/FinanceAccountsList';
import { RouteConfig } from './types';

const Auth0Landing = lazy(() => import('pages/Auth0/Auth0'));
const About = lazy(() => import('components/ProductionConsumptionWidget/AboutModal/AboutModal'));
const Lander = lazy(() => import('pages/Lander/Lander'));
const Logout = lazy(() => import('pages/Logout/Logout'));
const PanelPlacement = lazy(() => import('components/PanelPlacement/PanelPlacement'));
const PasswordReset = lazy(() => import('pages/PasswordReset/PasswordReset'));
const Signup = lazy(() => import('pages/Signup/Signup'));
const Support = lazy(() => import('pages/Support/Support'));
const DeepLink = lazy(() => import('pages/DeepLink/DeepLink'));
const Payments = lazy(() => import('pages/Payments/Payments'));
const FinanceAccountDetail = lazy(() => import('pages/PaymentsV3/FinananceAccountDetail/FinanceAccountDetail'));
const Profile = lazy(() => import('pages/Profile/Profile'));
const PaymentMethod = lazy(() => import('pages/Payments/PaymentMethod/PaymentMethod'));
const Service = lazy(() => import('pages/Service/Service'));
const Dashboard = lazy(() => import('pages/Dashboard/Dashboard'));
const ReferAFriend = lazy(() => import('pages/ReferAFriend/ReferAFriend'));
const EnergyAdvisor = lazy(() => import('pages/EnergyAdvisor/EnergyAdvisor'));
const UploadVoidedCheck = lazy(() => import('pages/Payments/UploadVoidedCheck/UploadVoidedCheck'));
const PaymentSetup = lazy(() => import('pages/Payments/PaymentSetup/PaymentSetup'));
const PaymentSetupV3 = lazy(() => import('pages/PaymentsV3/PaymentSetup/PaymentSetup'));
const InstantAchPaymentMethod = lazy(
  () => import('pages/Payments/PaymentMethod/InstantAchPaymentMethod/InstantAchPaymentMethod'),
);
const CheckUploadPaymentMethod = lazy(
  () => import('pages/Payments/PaymentMethod/CheckUploadPaymentMethod/CheckUploadPaymentMethod'),
);
const Questionnaire = lazy(() => import('pages/Questionnaire/Questionnaire'));
const PhsyicalSiteSurveyAvailability = lazy(
  () => import('pages/PhysicalSiteSurveyAvailability/PhysicalSiteSurveyAvailability'),
);
const EnergyProfile = lazy(() => import('pages/EnergyProfile/EnergyProfile'));
const BuildingAttributeUpdate = lazy(() => import('pages/BuildingAttributeUpdate/BuildingAttributeUpdate'));
const UtilityProvider = lazy(() => import('pages/UtilityProvider/UtilityProvider'));

export const ROUTES: RouteConfig[] = [
  { path: ROUTE.DEEPLINK, isPublic: true, component: DeepLink },
  {
    path: ROUTE.HOME,
    exact: true,
    isPublic: true,
    component: Lander,
  },
  { path: ROUTE.AUTH0, exact: true, isPublic: true, component: Auth0Landing },
  {
    path: ROUTE.PASSWORD_RESET,
    exact: true,
    component: PasswordReset,
    isPublic: true,
  },
  {
    path: ROUTE.SIGNUP,
    isPublic: true,
    exact: true,
    component: Signup,
  },
  { path: ROUTE.LOGOUT, isPublic: false, exact: true, component: Logout },
  {
    path: ROUTE.DASHBOARD,
    exact: true,
    component: Dashboard,
  },
  {
    path: ROUTE.DASHBOARD_ABOUT,
    exact: true,
    component: About,
    headerProps: {
      pageTitle: 'about.pageTitle',
    },
  },
  {
    path: ROUTE.SERVICE,
    component: Service,
    headerProps: {
      pageTitle: 'service.pageTitle',
    },
  },
  {
    path: ROUTE.SUPPORT,
    isPublic: true,
    exact: true,
    component: Support,
    headerProps: {
      pageTitle: 'support.pageTitle',
    },
  },
  {
    path: ROUTE.MOCK,
    isPublic: true,
  },
  {
    path: ROUTE.PANEL_PLACEMENT,
    component: PanelPlacement,
    isPublic: true,
    headerProps: {
      pageTitle: 'panelPlacement.pageTitle',
    },
  },
  {
    path: ROUTE.PAYMENT,
    exact: true,
    component: Payments,
    headerProps: {
      pageTitle: 'payments.pageTitle',
    },
    alternatePage: FinanceAccountDetail,
    alternatePageConfig: FeatureConfig.financeAccountDetail,
  },
  {
    path: ROUTE.FINANCE_ACCOUNTS,
    exact: true,
    component: FinanceAccountsList,
    headerProps: {
      pageTitle: 'payments.pageTitle',
    },
  },
  {
    path: ROUTE.FINANCE_ACCOUNTS_DETAIL,
    exact: true,
    component: FinanceAccountDetail,
    headerProps: {
      pageTitle: 'payments.pageTitle',
    },
  },
  {
    path: ROUTE.PAYMENT_METHOD,
    exact: true,
    component: PaymentMethod,
    headerProps: {
      pageTitle: 'account.payment.paymentMethod',
    },
  },
  {
    path: ROUTE.PAYMENT_CHECK_UPLOAD,
    exact: true,
    component: UploadVoidedCheck,
  },
  {
    path: ROUTE.PAYMENT_SETUP,
    exact: true,
    component: PaymentSetup,
    headerProps: {
      pageTitle: 'paymentSetup.pageTitle',
    },
    alternatePage: PaymentSetupV3,
    alternatePageConfig: FeatureConfig.paymentsV3,
  },
  {
    path: ROUTE.INSTANT_ACH_PAYMENT_METHOD,
    exact: true,
    component: InstantAchPaymentMethod,
    headerProps: {
      pageTitle: 'account.payment.addPaymentMethod',
    },
  },
  {
    path: ROUTE.CHECK_UPLOAD_PAYMENT_METHOD,
    exact: true,
    component: CheckUploadPaymentMethod,
    headerProps: {
      pageTitle: 'account.payment.paymentMethod',
    },
  },
  {
    path: ROUTE.PROFILE,
    exact: true,
    component: Profile,
    headerProps: {
      pageTitle: 'profile.pageTitle',
    },
  },
  {
    path: ROUTE.REFER,
    exact: true,
    component: ReferAFriend,
    featureConfig: FeatureConfig.referAFriend,
    headerProps: {
      pageTitle: 'refer.pageTitle',
    },
  },
  {
    path: ROUTE.ENERGY_ADVISOR,
    exact: true,
    component: EnergyAdvisor,
    featureConfig: FeatureConfig.energyAdvisorPage,
    headerProps: {
      pageTitle: 'energyAdvisor.pageTitle',
    },
  },
  {
    path: ROUTE.QUESTIONNAIRE,
    component: Questionnaire,
  },
  {
    path: ROUTE.PHYSICAL_SITE_SURVEY_AVAILABILITY,
    exact: true,
    component: PhsyicalSiteSurveyAvailability,
  },
  {
    path: ROUTE.ENERGY_PROFILE,
    exact: true,
    component: EnergyProfile,
    featureConfig: FeatureConfig.energyProfile,
    headerProps: {
      pageTitle: 'energyProfile.pageTitle',
    },
  },
  {
    path: ROUTE.PROPERTIES_BUILDING_ATTRIBUTE_UPDATE,
    exact: true,
    component: BuildingAttributeUpdate,
    featureConfig: FeatureConfig.energyProfile,
    headerProps: {
      pageTitle: 'energyProfile.pageTitle',
    },
  },
  {
    path: ROUTE.UTILITY_PROVIDER,
    exact: true,
    component: UtilityProvider,
    headerProps: {
      pageTitle: 'utilityProvider.pageTitle',
    },
    featureConfig: FeatureConfig.utilityProvider,
  },
  ...ONBOARDING_FEED_ITEM_ROUTES,
];
interface Props {
  routes: RouteConfig[];
}

export const RouteConfigSwitch: React.FC<Props> = (props: Props) => {
  const { routes } = props;
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingPage />}>
      <Switch>
        <Redirect from={ROUTE.LEGACY_ACCOUNT} to={pathname.replace(ROUTE.LEGACY_ACCOUNT, '')} />
        {routes?.map((route: RouteConfig) => {
          return <Route key={String(route.path)} {...route} />;
        })}
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </Suspense>
  );
};

const Routes: React.FC = () => {
  return <RouteConfigSwitch routes={ROUTES} />;
};
export default Routes;
